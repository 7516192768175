import React from "react";
import NavBar from "../components/NavBar";
import "../styles/Home.css";
import AboutUs from "../components/AboutUs";
import Services from "../components/Services";
import HomeText from "../components/HomeText";
import { Element } from "react-scroll";
const Home = () => {
  return (
    <div>
      <NavBar />
      <Element name='home'>
        <HomeText />
      </Element>
      <Element name='services'>
        <Services />
      </Element>

      <Element name='about'>
        <AboutUs />
      </Element>
    </div>
  );
};

export default Home;
